.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  background-color: #5e1a8b;
  background-image: url("/public/bigbanner.png"); /* Path to your image */
  background-size: auto 100%; /* Make it repeat horizontally */
  background-repeat: repeat-x; /* Only tile horizontally */
  animation: scroll-left 20s linear infinite; /* Adjust speed as needed */
}

.iframe-container {
  width: 100%;
  max-width: 800px;
  border: 5px solid #e52e71;
  background-color: white;
  border-radius: 10px;
}

a {
  color: #1da1f2;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  font-size: 16px;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
}

@keyframes scroll-left {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -100% 0;
  }
}
